
import ApiRequestService from '@/services/ApiRequestService'
import SnippetsService from '@/services/SnippetsService'
import StorageService, { LANGUAGE_KEY, USER_ID } from '@/services/StorageService'
import Multiselect from "vue-multiselect";
import LoggerService from "@/services/UsersService";
import { DataEmitterService } from "@/services/DataEmiterService";
import UsersService from "@/services/UsersService";

export default {

  components: {
    Multiselect
  },
  data() {
    let snippets = SnippetsService.getSnippetsByNamespaceAndLanguage( 'Users', StorageService.getFromStorage( LANGUAGE_KEY ) )

    return {
      requestedOrganization: null,
      usersSnippet: snippets ?? {},
      usersList: [],
      paginatedFacilitiesList: [],
      paginatedSearchedFacilitiesList: [],
      usersPaginatedList: [],
      rolesList: [],
      usersListUntouched: [],
      searchedData: [],
      model: +sessionStorage.getItem( 'model' ) ?? 0,
      searchField: '',
      newEventName: '',
      addOrEditSpace: '',
      dialog: false,
      spaceDialog: false,
      eventDialog: false,
      editEventDialog: false,
      addEventsDialog: false,
      dialogDelete: false,
      dialogCarousel: false,
      deleteEventDialog: false,
      dialogUpgrade: false,
      submitted: false,
      eventsSubmitted: false,
      spaceSubmitted: false,
      photoExists: false,
      userId: this.$route.params.id,
      organizationsNamesList: [],
      currentManagerOrganization: '',
      allowedOrganizations: false,
      editedIndex: -1,
      role: '',
      editedItem: {
        userId: '',
        email: '',
        photo: '',
        roles: '',
        organizationId: '',
        facilities: [],
        firstName: '',
        lastName: '',
        phoneNumber: '',
        password: '',
        lockPassword: '',
      },
      defaultItem: {
        userId: '',
        email: '',
        photo: '',
        roles: '',
        organizationId: '',
        facilities: [],
        firstName: '',
        lastName: '',
        phoneNumber: '',
        password: '',
        lockPassword: '',
      },
      multiSelectRoles: [],
      carouselPhotos: [],
      sortBy: '',
      editedPrefix: '',
      sortDesc: true,
      customSort( items, index, isDescending ) {

        if ( index[0] !== undefined ) {
          if ( index[0] === 'createDate' || index[0] === 'updateDate' ) {
            if ( this.sortDesc ) {
              return items.sort( ( a, b ) => new Date( b[index] ) - new Date( a[index] ) )
            } else {
              return items.sort( ( a, b ) => new Date( a[index] ) - new Date( b[index] ) )
            }
          } else if ( index[0] === '' || index[0] === 'id' ) {

            if ( this.sortDesc ) {
              return items.sort( ( a, b ) => b.id - a.id )
            } else {
              return items.sort( ( a, b ) => a.id - b.id )
            }

          } else if ( index[0] && index[0]?.includes( '.' ) ) {
            let i = index[0].split( '.' )[0]

          } else {

            if ( isDescending[0] === true ) {
              return items.sort( ( a, b ) => a[index]?.toLowerCase().localeCompare( b[index]?.toLowerCase() ) )
            } else if ( isDescending[0] === false ) {
              return items.sort( ( a, b ) => b[index]?.toLowerCase().localeCompare( a[index]?.toLowerCase() ) )
            }

          }
        } else {

          if ( isDescending[0] === true ) {
            return items.sort( ( a, b ) => a[index]?.toLowerCase().localeCompare( b[index]?.toLowerCase() ) )
          } else if ( isDescending[0] === false ) {
            return items.sort( ( a, b ) => b[index]?.toLowerCase().localeCompare( a[index]?.toLowerCase() ) )
          }

        }

        return items
      },
      errorKey: '',
      addEventsDialogErrorKey: '',
      toDeletePhotoIndex: '',
      pagesCount: 1,
      currentPage: 1,
      disableKeyField: true,
      oldFacility: {},
      editedEventItem: {},
      oldUser: {},
      toDeleteEvent: {},
      addressesDataList: [],
      showSpaces: [],
      facilitySpaces: [],
      facilityServices: [],
      facilities: [],
      selectedFacilities: '',
      eventsList: [],
      eventsNameList: [],
      oldEventsList: [],
      chips: [],
      chipsNameList: [],
      oldChips: [],
      eventsListHasBeenEdited: false,
    }
  },

  computed: {


    scrollbarTheme() {
      return this.$vuetify.theme.dark ? 'dark' : 'light';
    },

    defaultSpaceImage() {
      return require( '../../assets/images/users/no-image.svg' );
    },

  },

  watch: {

    spaceDialog( val ) {
      val || this.closeSpaceDialog()
    },

    model( val ) {
      sessionStorage.setItem( 'model', val )
    },


    dialogCarousel( val ) {
      val || this.closeDialogCarousel()
    },

    dialogDelete( val ) {
      val || this.closeDelete()
    },

    deleteEventDialog( val ) {
      val || this.closeDeleteEvent()
    },

    dialogUpgrade( val ) {
      val || this.closeUpgrade()
    },

  },

  beforeRouteLeave( to, from, next ) {
    sessionStorage.removeItem( 'model' );

    DataEmitterService.$emit( 'route-change')

    if ( this.$route.path.includes( 'user-edit' ) ) {
      return next();
    }

    StorageService.removeUserName();
    next()
  },

  async mounted() {
    await this.getUserApi();
    await this.getFacilitiesApi();
    await this.getRolesApi();
    await this.getOrganizationListIfHaveAccess();

    this.loading = false;
  },

  methods: {

    deletePhoto() {
      event.stopPropagation();
      this.dialogDelete = true;
    },

    deleteItemConfirm() {

      ApiRequestService.deleteRequest( `api/user-photo/${ this.editedItem.photo.id }` ).then()
      this.photoExists = false;

      this.closeDelete()
    },
    setRolesList( rolesData ) {
      this.rolesList = rolesData.length ? rolesData : [];
      this.rolesList?.forEach( item => {
        if ( item ) {
          this.multiSelectRoles.push( {
            name: item,
            checked: this.editedItem.roles.indexOf( item ) !== -1
          } )
        }
      } );
    },

    async deleteFacilityPhoto() {
      if ( !this.toDeletePhotoIndex || this.editedItem?.facility?.photos[this.toDeletePhotoIndex]?.isMain ) {
        return;
      }

      let selected = this.editedItem.facility.photos.splice( this.toDeletePhotoIndex, 1 );
      await ApiRequestService.deleteRequest( `api/facility-photos/${ selected[0].id }` );
      this.closeDelete();
    },

    async getUserApi() {
      this.editedItem = await ApiRequestService.getRequest( `api/admin-users/${ this.userId }` );
      this.selectedFacilities = this.editedItem.facilities ? this.editedItem.facilities?.map(item => item.id) : [];
      this.role = this.editedItem.roles?.[0] ?? 'Guest';
      this.requestedOrganization = this.editedItem.requestedOrganization;

      StorageService.saveUserName( this.editedItem.firstName + ' ' + this.editedItem.lastName );
      DataEmitterService.$emit( 'route-change')

      this.oldUser = this.getObjClone( this.editedItem )
      if ( this.editedItem.photo ) {
        this.photoExists = true;
      }

      if ( this.editedItem.organization?.id ) {
        this.editedItem.organizationId = this.editedItem.organization.id;
      }
    },

    async getFacilitiesApi() {
      ApiRequestService.getRequest( `api/admin-facilities` ).then(data => {
        this.facilities = data;
      });
    },

    async getRolesApi() {
      let rolesData = await ApiRequestService.getRequest( 'api/roles' );
      await this.setRolesList( rolesData );
    },

    getToLocaleDate( date ) {
      let options = {
        day: 'numeric',
        month: 'numeric',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
      }
      return new Date( date ).toLocaleDateString( 'en-US', options ) ?? 'null'
    },

    async uploadUserImage( dataToCreate ) {
      const formData = new FormData();
      formData.append( "file", dataToCreate, dataToCreate.name );
      let data = await ApiRequestService.postRequest( 'api/user-photo', formData );
      console.log( data )
      return data?.id;
    },

    async saveUploadedImage() {
      const dataFormUpdate = {};

      if ( this.editedItem?.photo ) {
        dataFormUpdate['photoId'] = await this.uploadUserImage( this.editedItem.photo );
      }

      const updatedUser = await ApiRequestService.updateRequest( `api/admin-users/${ this.editedItem.userId }`, dataFormUpdate )
      if ( updatedUser.errorMessage ) {
        return
      }
      this.editedItem.photo = updatedUser.photo;
      this.photoExists = true;
      this.$refs.fileupload.reset();
    },

    userIsValid( v ) {
      return ( v && v.length > 0 ) || this.facilitiesSnippet.required
    },

    dataIsValid() {
      const emailsValid = this.userIsValid( this.editedItem?.email )
      const isNotValid = []

      if ( emailsValid !== true ) {
        isNotValid.push( emailsValid )
      }


      if ( isNotValid.length ) {
        return isNotValid
      }

      return true
    },

    async editItem( item ) {
      let paginatedList = this.searchField ? this.paginatedSearchedFacilitiesList : this.paginatedFacilitiesList;
      let list = this.searchField ? this.searchedFacilitiesList : this.usersList;
      this.editedIndex = paginatedList.findIndex( fc => fc.id === item.id );
      this.listEditedIndex = list.findIndex( fc => fc.id === item.id );
      this.editedItem = this.getObjClone( paginatedList[this.editedIndex] );


      this.dialog = true
      this.disableKeyField = true
      this.oldFacility = this.getObjClone( this.editedItem )
    },

    getObjClone( obj ) {
      return JSON.parse( JSON.stringify( obj ) );
    },

    closeDelete() {
      this.dialogDelete = false;
      this.submitted = false;
    },

    async save() {
      this.submitted = true
      this.updateUser()
    },

    async updateUser() {
      let isValid = this.dataIsValid()
      if ( isValid !== true ) {
        return
      }

      await this.updatedUserRequest( this.oldUser )
    },

    async approveMembership() {
      const updatedUser = await ApiRequestService.updateRequest( `api/admin-users/${ this.editedItem.userId }`, {
        roles: ['Member'],
        organizationId: this.requestedOrganization.id
      } ) ;

      if ( updatedUser.errorMessage ) {
        return
      }

      this.requestedOrganization = null;
      this.editedItem.roles = updatedUser.roles;
      this.role = updatedUser.roles?.[0] ?? 'Guest';

      this.editedItem.organizationId = updatedUser?.organization?.id;
      this.multiSelectRoles = [];
      this.setRolesList( this.rolesList );
    },

    async getChangedData( oldUser ) {
      const dataFormUpdate = {}
      for ( let [ key, value ] of Object.entries( this.editedItem ) ) {
        if ( key === 'photo' ) {
          continue;
        }

        let isTheSame = false;

        if ( key === 'roles' ) {
          dataFormUpdate[key] = [this.role];
          continue;
        }

        if ( key === 'organization' ) {
          if(!this.allowedOrganizations){
            continue;
          }
          if ( value.name === oldUser[key].name ) {
            isTheSame = true;
            continue;
          } else {
            isTheSame = false;
          }
        }
        if ( key === 'facilities' ) {
          if(this.allowedOrganizations){
            continue;
          }
          dataFormUpdate['facilityIds'] = this.selectedFacilities ?? [];
          continue
        }


        if ( oldUser[key] === this.editedItem[key] ) {
          isTheSame = true;
        }

        if ( isTheSame ) {
          continue;
        }

        dataFormUpdate[key] = this.editedItem[key]
      }
      return dataFormUpdate
    },
    async updatedUserRequest( oldUser ) {
      const dataFormUpdate = await this.getChangedData( oldUser );
      if ( !Object.keys( dataFormUpdate ).length ) {
        return;
      }
      const updatedUser = await ApiRequestService.updateRequest( `api/admin-users/${ this.editedItem.userId }`, dataFormUpdate )

      if ( updatedUser.errorMessage ) {
        console.log( 'updatedUser.errorMessage', updatedUser.errorMessage )
        return;
      }

      if ( !updatedUser.roles ) {
        this.errorKey = "Role is required"
        return;
      }

      this.editedItem.roles = updatedUser.roles;
      this.role = updatedUser.roles?.[0] ?? 'Guest';
      this.editedItem.organizationId = updatedUser?.organization?.id;
      this.editedItem.firstName = updatedUser.firstName;
      this.editedItem.lastName = updatedUser.lastName;
      this.editedItem.email = updatedUser.email;
      this.editedItem.phoneNumber = updatedUser.phoneNumber;
    },

    setPhoto( file ) {
      if ( file ) {
        this.editedItem.photo = file;
      }
    },

    async saveUser() {
      let isValid = this.dataIsValid()
      if ( isValid !== true ) {
        return
      }
      UsersService.setEmail( this.editedItem?.email )

      let dataToCreate = {};
      for ( let [ key, value ] of Object.entries( this.editedItem ) ) {
        if ( key === 'organization' ) {
          if ( !value && !this.currentManagerOrganization?.id ) {
            continue;
          }
          value = this.currentManagerOrganization?.id ?? value
        }
        if ( value ) {
          dataToCreate[key] = value;
        }
      }
      const userData = await ApiRequestService.postRequest( 'api/admin-users', dataToCreate );

      if ( userData?.errorMessage ) {
        this.errorKey = userData?.errorMessage?.split( ',' );
        return;
      }
      if ( !userData ) {
        return;
      }
      this.setNewUserToUsersList( userData )

      UsersService.clearAllData()
    },

    selectOrganization( id ) {
      this.editedItem.organizationId = id;
      this.$forceUpdate()
    },

    async getOrganizationListIfHaveAccess() {
      let userId = StorageService.getFromStorage( USER_ID );
      let currentUser = await ApiRequestService.getRequest( `api/admin-users/${ userId }` );
      if ( currentUser?.roles?.includes( 'CompanyManager' ) && !currentUser?.roles?.includes( 'Admin' ) ) {
        this.currentManagerOrganization = currentUser.organization;
        this.allowedOrganizations = true;
      }else{
        this.allowedOrganizations = false;
      }
      let organizationsData = await ApiRequestService.getRequest( 'api/organizations' );
      await this.setOrganizationsList( organizationsData.data );
    },

    setOrganizationsList( organizationsData ) {
      this.organizationsList = organizationsData;
      this.organizationsList?.forEach( organization => this.organizationsNamesList.push( {
        name: organization.name,
        id: organization.id
      } ) );
    },
  }
}

